html {
  overflow: hidden;
}

.loading {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.cell {
  width: 180px;
  height: 120px;
  padding: 0%;
  margin: 2px;
  clip-path: polygon(20% 0, 80% 0, 100% 50%, 80% 100%, 20% 100%, 0% 50%);
  background-color: #525252; /* default color */
  text-align: center;
  overflow: hidden;
  position: relative;
}

.cell:hover {
  background-color: #e0e0e0;
  opacity: 0.7;
}

/* border for hex */
.inner-cell {
  content: "";
  display: block;
  position: relative;
  top: 1px;
  left: 1px;
  height: 118px;
  width: 178px;
  background-color: #8a91a0; 
  clip-path: inherit;
  
}

.cell-invisible {
  background-color: #fff;
  
}

.fancy-empty-cell {
  background-color: #ffffff;
  opacity: 1;
  background: repeating-linear-gradient( -45deg, #cecece, #cecece 4.5px, #ffffff 4.5px, #ffffff 22.5px );
  font-weight: bold;
  display: table-cell;
  vertical-align: middle;
  font-size: large;
}

.tables {
  max-height: calc(100vh - 114px);
  margin-top: 65px;
  /* margin-bottom: 43px; */
  overflow-y: scroll;
  overflow-x: scroll;
  padding-left: 230px;
  padding-right: 5px;  
  position: relative;
}

canvas {
  position: absolute;
  left: 0;
  top: 0;
  /* width: 3726px;
  height: 2537px; */
  pointer-events: none;
}

div.tables div.row {
  width: max-content;
  margin-right: 0;
  margin-left: 0;
}

.half-cell {
  opacity: 0%;
  width: 90px;
}

.cell-name {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

.mlti-pos {
  display: inline;
  margin-top:  auto;
  font-size: 18px;
  text-align: center;
  position: absolute;
  bottom:0;
  left: 0;
  width: 100%;
  font-weight: bold;
  font-family:'Times New Roman', Times, serif;
}

sup, sub {
  font-style: normal;
}

.su-pos {
  margin-top:  auto;
  font-size: 16px;
  text-align: center;
  font-style: italic;
}

.data-window {
  position: fixed;
  top: 67px;
  left: 2px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  max-height: calc(100% - 150px);
  overflow: auto;
}

.data-window-left {
  left: calc(100% - 300px);
  max-width: 279px;
}

.data-window-bottom {
  top: calc(100% - 406px);
  left: 225px;
}

.data-window-top {
  display: flex;
  padding: 1rem;
  font-size: 1.25rem;
  justify-content: space-between;

}

.hover-table-cell {
  cursor: pointer;
}

.hover-table-cell:hover {
    color: #0d6efd;
    text-decoration: underline;
}

.modal-content2 {
  max-height: 500px;
  overflow-y: auto;
}

.navbar {
  border: 1px solid #ccc;
}

.nav-link {
  cursor: pointer;
}

.diminput {
  display: block;
  width: 90px;
  font-size: 15px;
  text-align: center;
  position: relative;
  z-index: 10;
  background-color: white;
  color: black;
  border: 1px solid black;
  margin-right: 10px;
}

.nameinput {
  display: block;
  width: 150px;
  font-size: 15px;
  text-align: center;
  position: relative;
  z-index: 10;
  background-color: white;
  color: black;
  border: 1px solid black;
  margin-right: 10px;
  overflow: hidden;
}

.v-align {
  display: inline;
  vertical-align: middle;
}

.footbar-button {
  margin-right: 10px;
  max-width: fit-content;
}

@media (max-width:992px) {
  .footbar-button {
    margin: 5px;
  }
  .footbar-input {
    margin: 5px;
  }
  .navbar-nav {
    align-items: center;
  }
}

#table-scale {
  transform: scale(1);
}



.diminput sup {
  display:inline-flex;
  min-width: 10px;
}

.modal-drag-area {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.drag-modal {
  position: relative;
  margin: 10px;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 2000;
}



.modal-title2 {
  display: flex;
  padding: 1rem;
  cursor: move;
  font-size: 1.25rem;
  background-color: #0d6efd;
  color: white;
  justify-content: space-between;

}

.modal-content2 {
  padding: 1rem;
  border-top: 1px solid #ccc;
  /*border-bottom: 1px solid black; */
  font-size: 1rem;
}

.modal-footer2 {
  padding: 1rem;
  font-size: 1rem;
  text-align: end;
}

/* .btn {
  margin: 5px;
} */


.hidden {
  display: none;
}

.small-cell {
  width: 50px;
}

td,th {
  vertical-align: middle;
  text-align: center;
}

td button {
  min-width: 31px;
}

#modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(55, 55, 55, 0.6);
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 1500;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.toolbar-class {
  border: 1px solid #dee2e6 !important;
  border-radius: 0.375rem !important;
}

.rdw-option-wrapper {
  border-radius: 0.375rem !important;
}

.GK-layers-image {
  width: 566px;
}

.showPassword {
  cursor: pointer;
  user-select: none;
}

.grey-background {
  background-color: #e9ecef;
}

.loading-button {
  position: relative;
  align-items: center;
  text-align: center;
}

.loading-img {
  content: "";
  position: absolute;
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-radius: 50%;
}

.loading-button .button-text {
  visibility: hidden;
  opacity: 0;
}

.tab-content-border {
  border: 0.8px solid #dee2e6;
  border-top: 0px;
  padding: 10px;
}